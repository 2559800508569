import React from "react";
// import React, { useEffect, useRef, useState } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import video from "./assets/compressed4.mp4";
import defaultImg from "./assets/Screenshot_1.png";
import "./App.css";
function App() {
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }

  // function changePage(offSet) {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  // }

  // function changePageBack() {
  //   changePage(-1);
  // }

  // function changePageNext() {
  //   changePage(+1);
  // }
  // let div = useRef(document.querySelector(".react-pdf__Page"));

  // useEffect(() => {
  //   // first
  //   // console.log(window.screen.availWidth);
  //   // console.log("div ", div);

  //   return () => {
  //     // second
  //   };
  // }, []);

  return (
    <div className="App">
      {/* <header className="App-header">
        <Document file="/sample.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page height="600" pageNumber={pageNumber} />
        </Document>
        <p> Page {pageNumber} of {numPages}</p>
        { pageNumber > 1 && 
        <button onClick={changePageBack}>Previous Page</button>
        }
        {
          pageNumber < numPages &&
          <button onClick={changePageNext}>Next Page</button>
        }
      </header> */}
      <center>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_1.jpg").default} alt="" />
        </div>
        <div className="big-banner">
          <video controls={true} poster={defaultImg} playsInline src={video} type="video/mp4">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_2.jpg").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_3.png").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_4.jpg").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_5.jpg").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_6.jpg").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_7.jpg").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_8.jpg").default} alt="" />
        </div>
        <div className="img-wrapper">
          <img src={require("./assets/docs_Page_9.jpg").default} alt="" />
        </div>
        {/* <div className="center"> */}
        {/* <Document file="/essentials.pdf" onLoadSuccess={onDocumentLoadSuccess}> */}
        {/* <Document file="/essentials.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            // height="600"
            pageNumber={pageNumber}
            width={window.screen.availWidth}
          />
        </Document> */}
        {/* {Array.from(new Array(numPages), (el, index) => (
            <>
              {index === 1 ? (
                <div className="big-banner" key={`page_${index + 1}`}>
                  <video controls>
                    <source
                      src={require("./assets/compressed4.mp4").default}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.screen.availWidth}
                />
              )}
            </>
          ))}
        </Document> */}
        {/* <p>
          {" "}
          Page {pageNumber} of {numPages}
        </p>
        {pageNumber > 1 && (
          <button onClick={changePageBack}>Previous Page</button>
        )}
        {pageNumber < numPages && (
          <button onClick={changePageNext}>Next Page</button>
        )} */}
      </center>
      <footer className="footer">
        <div className="wrapper">
          <div className="download">
            <a
              href="https://apps.apple.com/uz/app/1sonia/id6444478902"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("./assets/appleStore.svg").default}
                alt="appleStroe"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=uz.sonia1.soniaapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require("./assets/googlePlay.svg").default}
                alt="googlePlay"
              />
            </a>
          </div>
          <div className=" links">
            <a href="https://drive.google.com/u/0/uc?id=1NV2gmkxxtZ_sSWyTCrH3gB31zU7NbboT&export=download">
              <img src={require("./assets/pdfIcon.png").default} alt="web" />
              <span>PDF formatda yuklab oling</span>
            </a>
            {/* <p>
              <img
                src={require("./assets/locationIcon.svg").default}
                alt="phone"
              />
              <span>Toshkent, Chilonzor, <br /> Gavhar Kochasi, PGS.UZ</span>
            </p> */}
            <p>
              <img
                src={require("./assets/phoneIcon.svg").default}
                alt="phone"
              />
              <span>+998 (99) 600-60-00</span>
            </p>
            <a href="https://info.1sonia.uz/">
              <img src={require("./assets/globeIcon.svg").default} alt="web" />
              <span> info.1sonia.uz</span>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
